
<script lang="ts">import { createViewer, removeViewer, updateViewer, applyGlobalStyles, getViewer, listenToViewerChanges } from '@yuion/svg-viewer';
import { onDestroy, onMount } from 'svelte';
/** URL to the SVG file */
export let src;
/** List of labels to render on top of the SVG */
export let labels;
/** List of labels to render on top of the SVG */
export let actions;
/** List of DOM elements to render over parts of the SVG */
export let features;
/** Custom CSS styles to apply to the SVG */
export let styles;
/** Element or Point to focus on within the SVG */
export let focus;
/** Center point of the SVG. X and Y values between 0 and 1 */
export let options;
/** Center point of the SVG. X and Y values between 0 and 1 */
export let center = { x: .5, y: .5 };
/** Zoom level of the SVG. Value between 1 and 10 */
export let zoom = 1;
/**  */
export let ratio = 1;
/** ID of the active SVG viewer */
let viewer;
/** Container element for the SVG viewer */
let viewer_el;
applyGlobalStyles();
$: if (src) {
    createView();
}
$: if (viewer) {
    updateViewer(viewer, { labels, features, styles, zoom, center, focus, actions, options });
}
function createView() {
    if (!viewer_el)
        return;
    if (viewer)
        removeViewer(viewer);
    createViewer({ url: src, element: viewer_el, labels, features, styles, focus, actions, options }).then(id => {
        var _a;
        viewer = id;
        ratio = ((_a = getViewer(viewer)) === null || _a === void 0 ? void 0 : _a.ratio) || 1;
        listenToViewerChanges(id).subscribe((view) => {
            zoom = view.zoom;
            ratio = view.ratio;
        });
        setTimeout(() => updateViewer(viewer, { zoom: 1 }), 30);
    });
}
onMount(() => createView());
onDestroy(() => removeViewer(viewer));
</script>
<style>#viewer {
  top: 1em;
  left: 1em;
  right: 1em;
  bottom: 1em;
}</style>
<div id="viewer" class="absolute" bind:this={viewer_el}></div>
